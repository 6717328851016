<template>
  <div id="nav-bar" class="flex h-8 text-lg text-gray">
    <router-link to="/products" id="products-link">
      Products
    </router-link>
    <router-link to="/support" class="pl-2" id="support-link">
      Support
    </router-link>
    <router-link to="/about-us" class="pl-2" id="about-link">
      About Us
    </router-link>
    <router-link to="/contact" class="pl-2" id="contact-link">
      Contact
    </router-link>
  </div>
</template>

<script lang="js">
export default{
  name: "Navigation",
};
</script>

<style lang="postcss">
a.router-link-active {
  @apply text-blue;
}
</style>
