<template>
  <div id="page-wrap" class="flex flex-col h-screen">
    <header id="header" class="pt-8 px-4">
      <img
        alt="Surgistar logo"
        src="@/assets/logo.png"
        id="logo-image"
        class="w-4/5 md:w-2/3 lg:w-1/3"
      />
      <Navigation />
    </header>
    <router-view class="p-4 flex-grow" />
    <Footer />
  </div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: {
    Navigation,
    Footer,
  },
};
</script>
