<template>
  <div id="footer" class="h-auto px-4 py-2 text-sm text-cyan bg-blue">
    <p>
      Surgistar is a fictional company. Product images, names and features are
      the intellectual property of Skytron and have been lifted from
      <a
        class="underline"
        href="https://archive.org/details/manual_Skytron_Full_Line_Surgical_Table_Brochure/"
        >this brochure.</a
      >
      This is a self-commissioned Vue.js learning effort made by Oliver Lehner
      in 2021.
    </p>
  </div>
</template>

<script lang="js">
export default{
  name: "Footer",
};
</script>
